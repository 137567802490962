import React, { useState } from "react";

const RippleButton = ({ children }) => {
  const [rippleStyle, setRippleStyle] = useState({});
  const [isRippling, setIsRippling] = useState(false);

  const createRipple = (event) => {
    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    setRippleStyle({
      width: size,
      height: size,
      left: x,
      top: y,
    });

    setIsRippling(true);
  };

  // Reset ripple effect after animation
  const handleAnimationEnd = () => {
    setIsRippling(false);
  };

  return (
    <div className="ripple-button " onClick={createRipple}>
      {isRippling && (
        <span
          className="ripple"
          style={rippleStyle}
          onAnimationEnd={handleAnimationEnd}
        />
      )}
      {children}
    </div>
  );
};

export default RippleButton;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import RippleButton from "./rippleButton";
import TopHeader from "./topHeader";

const UserTypeSelection = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [userType, setUserType] = useState(null); // State to store selected user type

  const handleLogout = () => {
    localStorage.setItem("loggedIn", "false");
    navigate("/login");
  };

  const handleUserTypeSelection = (type) => {
    alert(type);
    setUserType(type); // Update the state with selected user type
    if (type === "patient") {
      navigate("/patient-registration"); // Navigate to patient registration page
    } else if (type === "doctor") {
      navigate("/doctor-registration"); // Navigate to doctor registration page
    }
  };

  const logoutButton = (
    <span
      onClick={handleLogout}
      className=" py-1 px-4 rounded-5 border border-dark m-3 "
      style={{ cursor: "pointer" }}
    >
      Logout
    </span>
  );

  return (
    <div className="screen w3-indigo p-0 ">
      <TopHeader rightChild={logoutButton}>ACCOUNT IDENTIFICATION</TopHeader>
      <div
        className=" w-100 container w3-display-container "
        style={{ height: "90vh" }}
      >
        <div className="w3-display-middle w-100 container p-3">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 ">
              <div className="text-center mb-3">
                <b className="text-center pb-4 w3-block text-medium bold"></b>
                <div>
                  <span>Choose the appropriate account type to continue.</span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div
                    style={{ height: "100%" }}
                    className="mt-4 animate__animated animate__fadeInRight"
                    onClick={() => navigate("/patient-registration")}
                  >
                    <RippleButton>
                      <div className="card option text-left">
                        <div className="card-body">
                          <img
                            draggable="false"
                            src="patients.png"
                            alt="patients"
                            className="img-fluid rounded-circle my-2"
                            style={{
                              height: "60px",
                              borderRadius: "100%",
                            }}
                          />
                          <div className="pb-3 bold mb-1">
                            <b className="text-medium">PATIENT</b>
                          </div>
                          <div className="pb-3">
                            I am seeking healthcare support
                          </div>
                        </div>
                      </div>
                    </RippleButton>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className="mt-4 animate__animated animate__fadeInRight"
                    onClick={() => navigate("/doctor-registration")}
                  >
                    <RippleButton>
                      <div className="card option">
                        <div className="card-body">
                          <img
                            draggable="false"
                            src="doctors.png"
                            alt="doctors"
                            className="img-fluid rounded-circle my-2"
                            style={{
                              height: "60px",
                              borderRadius: "100%",
                            }}
                          />
                          <div className="pb-3 text-medium bold mb-1">
                            <b className="text-medium">DOCTOR</b>
                          </div>
                          <div className="pb-3">
                            I am a healthcare professional.
                          </div>
                        </div>
                      </div>
                    </RippleButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTypeSelection;

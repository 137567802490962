import React from "react";
import { ArrowBack } from "@mui/icons-material";
import RippleButton from "./rippleButton";

const TopHeader = ({ children, onClick, rightChild }) => {
  return (
    <div className="top-header w3-display-container">
      <div className="w3-display-left">
        {onClick ? (
          <div
            className="p-3"
            style={{ display: "inline-block", verticalAlign: "middle" }}
          >
            <RippleButton>
              {" "}
              {/* Pass the onClick prop here */}
              <span className="p-3" onClick={onClick}>
                <ArrowBack />
              </span>
            </RippleButton>
          </div>
        ) : (
          <div
            className="p-3"
            style={{ display: "inline-block", verticalAlign: "middle" }}
          ></div>
        )}

        <div style={{ display: "inline-block", verticalAlign: "middle" }}>
          <b style={{ textTransform: "uppercase" }}>{children}</b>
        </div>
      </div>
      <div className="w3-display-right">{rightChild}</div>
    </div>
  );
};

export default TopHeader;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dashboard as DashboardIcon } from "@mui/icons-material";
import PasswordSetup from "../components/password";
import UserTypeSelection from "../components/usertypeSelection";

const UserDashboard = () => {
  const navigate = useNavigate();
  // Safely parse user data from localStorage
  const User = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const token = localStorage.getItem("token");
  const [userDetails, setUserDetails] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  if (User.Account.Status === "Unauth") {
    return <PasswordSetup />;
  }
  if (User.Account.UserType === "pending") {
    return <UserTypeSelection />;
  }

  const handleLogout = () => {
    localStorage.setItem("loggedIn", "false");
    navigate("/login");
  };

  return (
    <div className="screen bg-light-color w3-display-container">
      <span
        onClick={handleLogout}
        className="w3-display-topright py-1 px-4 rounded-5 border border-dark m-3 "
        style={{ cursor: "pointer" }}
      >
        Logout
      </span>
      <div className="w3-display-middle w-100 container text-center">
        <DashboardIcon fontSize="large" />
        <h2>
          Welcome <br></br> {JSON.stringify(User)}
        </h2>
      </div>
    </div>
  );
};

export default UserDashboard;

import React, { useState } from "react";
import RippleButton from "./rippleButton";
import TopHeader from "./topHeader";
import { useNavigate } from "react-router-dom";

const DoctorRegistration = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    photograph: null,
    certificate1: null,
    certificate2: null,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    gender: "",
    dob: "",
    address: "",
    hospital: "",
    hospitalAddress: "",
    hospitalContact: "",
    yearsOfService: "",
    medicalCategorization: "",
  });

  const medicalCategories = [
    "Cardiology",
    "Dermatology",
    "Pediatrics",
    "Neurology",
    "Orthopedics",
    "Radiology",
    "Surgery",
    "General Practice",
    "Psychiatry",
    // Add more categories as needed
  ];

  // Back navigation handler
  const handleGoBack = () => {
    navigate(-1);
  };

  // General input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // File input change handler with preview
  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const previewUrl = file ? URL.createObjectURL(file) : null;
    setFormData({ ...formData, [name]: { file, previewUrl } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Data:", formData);
    // Here, add form submission logic, such as sending data to the server
  };

  // Helper function to render image upload with preview
  const renderImageUpload = (name, label) => (
    <div className="form-group mb-3 text-center">
      <div
        className="upload-preview mt-4"
        onClick={() => document.getElementById(name).click()}
        style={{
          cursor: "pointer",
          borderRadius: "10px",
          display: "inline-block",
          overflow: "hidden",
        }}
      >
        <img
          src={formData[name]?.previewUrl || "placeholder.png"}
          alt={label}
          style={{
            border: "1px solid #ddd",
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </div>
      <input
        type="file"
        id={name}
        name={name}
        accept="image/*, .pdf"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );

  return (
    <div className="screen w3-indigo p-0">
      <TopHeader onClick={handleGoBack}>Doctor Registration</TopHeader>
      <div className="container w-100">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <form onSubmit={handleSubmit} className="form">
              {/* Photograph */}
              {renderImageUpload("photograph", "Photograph")}

              {/* Personal Information */}
              <h5 className="mt-4">Personal Information</h5>
              <div className="form-group mb-3">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter first name"
                />
              </div>

              <div className="form-group mb-3">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter last name"
                />
              </div>

              <div className="form-group mb-3">
                <label>Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter phone number"
                />
              </div>

              <div className="form-group mb-3">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter email"
                />
              </div>

              <div className="form-group mb-3">
                <label>Gender</label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>

              <div className="form-group mb-3">
                <label>Date of Birth</label>
                <input
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>

              <div className="form-group mb-3">
                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter address"
                />
              </div>

              {/* Professional Information */}
              <h5 className="mt-4">Professional Information</h5>
              <div className="form-group mb-3">
                <label>Hospital Working In</label>
                <input
                  type="text"
                  name="hospital"
                  value={formData.hospital}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter hospital name"
                />
              </div>

              <div className="form-group mb-3">
                <label>Hospital Address</label>
                <input
                  type="text"
                  name="hospitalAddress"
                  value={formData.hospitalAddress}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter hospital address"
                />
              </div>

              <div className="form-group mb-3">
                <label>Hospital Contact</label>
                <input
                  type="tel"
                  name="hospitalContact"
                  value={formData.hospitalContact}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter hospital contact"
                />
              </div>

              <div className="form-group mb-3">
                <label>Years of Service</label>
                <input
                  type="number"
                  name="yearsOfService"
                  value={formData.yearsOfService}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter years of service"
                />
              </div>

              <div className="form-group mb-3">
                <label>Medical Categorization</label>
                <select
                  name="medicalCategorization"
                  value={formData.medicalCategorization}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select category</option>
                  {medicalCategories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              {/* Professional Certificates */}
              <h5 className="mt-4">Professional Certificates</h5>
              {renderImageUpload("certificate1", "Professional Certificate 1")}
              {renderImageUpload("certificate2", "Professional Certificate 2")}

              {/* Submit Button */}
              <div className="text-center mt-4">
                <RippleButton type="submit">
                  <button type="submit" className="btn btn-primary">
                    Submit Registration
                  </button>
                </RippleButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorRegistration;

import React from "react";
import RippleButton from "./rippleButton";
import TopHeader from "./topHeader";
import { useNavigate } from "react-router-dom";

const PatientRegistration = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="screen w3-indigo p-0 ">
      <TopHeader onClick={handleGoBack}>Patient Registration</TopHeader>
      <div className=" w-100 container ">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 ">
            <div className="row">
              <div className="col-sm-6">
                <div className="mt-4 animate__animated animate__fadeInRight">
                  <RippleButton>
                    <div className="card option  text-left">
                      <div className="card-body ">
                        <img
                          draggable="false"
                          src="patients.png"
                          alt="patients"
                          className="img-fluid rounded-circle my-3"
                          style={{
                            height: "80px",
                            borderRadius: "100%",
                          }}
                        />
                        <div className="pb-3   bold mb-2">
                          <b className="text-medium">PATIENT</b>
                        </div>
                        <div className="pb-3  ">
                          I am seeking healthcare support
                        </div>
                      </div>
                    </div>
                  </RippleButton>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mt-4 animate__animated animate__fadeInRight">
                  <RippleButton>
                    <div className="card option ">
                      <div className="card-body ">
                        <img
                          draggable="false"
                          src="doctors.png"
                          alt="patients"
                          className="img-fluid rounded-circle my-3"
                          style={{
                            height: "80px",
                            borderRadius: "100%",
                          }}
                        />
                        <div className="pb-3  text-medium bold mb-2">
                          <b className="text-medium">DOCTOR</b>
                        </div>
                        <div className="pb-3  ">
                          I am a healthcare professional.
                        </div>
                      </div>
                    </div>
                  </RippleButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientRegistration;
